import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home, Login, Shop, Profile, Guide, Basket, NotFound } from './pages';
import './App.css';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import { useState } from 'react';
import { CartContext } from './hooks/useCartContext';
import { SelectionContext } from './hooks/useSelectionContext';
import { ChakraProvider } from '@chakra-ui/react';
import { customTheme, initialSelectionState } from './resources';

function App() {
  const [cartState, setCartState] = useState({});
  const [selectionState, setSelectionState] = useState(initialSelectionState);

  return (
    <ChakraProvider theme={customTheme}>
      <SelectionContext.Provider value={{ selectionState, setSelectionState }}>
        <CartContext.Provider value={{ cartState, setCartState }}>
          <BrowserRouter>
            <Routes>
              <Route element={<AuthOutlet fallbackPath="login" />}>
                <Route path="/" element={<Home />}>
                  <Route path="shop" index element={<Shop />} />
                  <Route path="guide" element={<Guide />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="basket" element={<Basket />} />
                </Route>
              </Route>
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Login />} />
              <Route path="*" element={<NotFound/>} />
            </Routes>
          </BrowserRouter>
        </CartContext.Provider>
      </SelectionContext.Provider>
    </ChakraProvider>
  );
}

export default App;
