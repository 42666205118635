import React, { useEffect, useRef, useState } from 'react';
import './Selector.css'

type MultiSelectorProps = {
  placeholder: string;
  listItems: string[];
  selectedValues: { [key: string]: true };
  handleSelectionChange: (update: string) => void;
  handleSelectionClear: () => void;
};

export function MultiSelector({
  placeholder,
  listItems,
  handleSelectionChange,
  handleSelectionClear,
  selectedValues,
}: MultiSelectorProps) {
  const [isFocus, setIsFocus] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredItems, setFilteredItems] = useState<string[]>(listItems);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Filter items based on input value
  useEffect(() => {
    if (inputValue) {
      setFilteredItems(
        listItems.filter(item =>
          item.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    } else {
      setFilteredItems(listItems);
    }
  }, [inputValue, listItems]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsFocus(false);
    }
  };

  useEffect(() => {
    if (isFocus) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isFocus]);

  const handleClick = (item: string) => {
    handleSelectionChange(item);
    // setInputValue(''); // Clear input after selection
  };

  const handleClear = () => {
    handleSelectionClear();
    setInputValue('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const selectedItems = Object.keys(selectedValues).length;
  let value = inputValue
  if(!isFocus) {
    if(selectedItems === 1) value = Object.keys(selectedValues)[0]
    else if (selectedItems > 1) value = `${selectedItems} selected`
    else value = ''
  }

  const sortedItems = filteredItems.sort()

  return (
    <div className='selector_container' ref={dropdownRef}>
      <span className="input_clear_span">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          onClick={() => setIsFocus(true)}
          className={isFocus ? 'input_focus': 'input'}
        />
        <div
          className={isFocus ? "input_clear_focus" : "input_clear"}
          onClick={handleClear}
        >
          {isFocus ? selectedItems : ''}
          {!isFocus && selectedItems ? 'X' : ''}
        </div>
      </span>
      {isFocus && (
        <div className="menu_list">
          {filteredItems.length > 0 ? (
            sortedItems.map((item) => (
              <div
                key={item}
                onClick={() => handleClick(item)}
                className={selectedValues[item] ? "menu_item_selected" : "menu_item"}
              >
                {item}
              </div>
            ))
          ) : (
            <div className="menu_item">No matches found</div>
          )}
        </div>
      )}
    </div>
  );
}
