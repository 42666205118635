import { createContext, useContext } from "react";
import { CartContextState } from "../types";

export const CartContext = createContext<CartContextState | undefined>(undefined);

export function useCartContext() {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCartContext must be used within a CartContextProvider');
  }
  return context;
}