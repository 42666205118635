export type ProductInfo = {
  id: string,
  title: string,
  price: number,
  sale_price: number,
  barcode: string,
  brand: string,
  store: string,
  volume: number,
  unit: string,
  promotion_id: string | null,
  image_url: string,
  category: string,
  sub_category: string,
  product_group: string,
  self_assigned: boolean,
  category_unit: string,
  category_volume: number,
  created_at: Date,
  updated_at: Date,
  is_flagged: boolean
};

export type CartProductInfo = ProductInfo & {
  count: number;
  savingsProductId: string;
  savingsProductPrice: number;
  isPriceChoice: boolean;
  isValueChoice: boolean
}

export const enum Stores {
  WOOLWORTHS = 'woolworths',
  CHECKERS = 'checkers',
}

export enum JobType {
  PRODUCT_MAPPING = "product-mapping",
  NAME_SEARCH = "name-search",
  CATEGORY_SEARCH = "category-search",
  CATEGORY_RANK = "category_rank",
  FLAG_PRODUCT = 'flag_product'
}

type StoreState = {
  [key: string]: boolean;
};

export type SearchState = {
  category: string;
  id: string | null;
  subCategories: {[key: string]: true};
  minVolume: number | null;
  maxVolume: number | null;
  brands: {[key: string]: true};
  store: StoreState;
};

export type RankingDetails = {
  bestValueProductId: string,
  bestValueProductAmount: number,
  bestPriceProductId: string,
  bestPriceProductAmount: number
}

export type SelectionState = { [key: string]: SearchState };

export type CartContextState = {
  cartState: { [key: string]: CartProductInfo };
  setCartState: React.Dispatch<
    React.SetStateAction<{ [key: string]: ProductInfo }>
  >;
};
export type SelectionContextState = {
  selectionState: SelectionState;
  setSelectionState: React.Dispatch<React.SetStateAction<SelectionState>>;
};

export type MultiSelectorProps = {
  placeholder: string;
  listItems: string[];
  selectedValues: {[key: string]: true};
  handleSelectionChange: (update: any) => void;
  handleSelectionClear: () => void
}