export function filterTitle(title: string, volume: number, unit: string) {
  const filteredTitle = title.toLowerCase().replace(String(volume), '').trim()
  const withoutUnit = filteredTitle.split(' ').slice(0, -1).join(' ').trim()
  const withoutPerAvg = withoutUnit.replace(' per', '').replace(' avg', '')
  return withoutPerAvg
}

export function roundNonZero(value: number) {
  if (value >= 1) {
    // For values greater than or equal to 1, round to 2 decimal places
    return Math.round(value * 100) / 100;
} else if (value > 0 && value < 1) {
    // For values less than 1, round the first non-zero digit
    const decimalStr = value.toString();
    const decimalIndex = decimalStr.indexOf('.') + 1;

    // Find the first non-zero digit after the decimal point
    let firstNonZeroIndex = decimalIndex;
    while (decimalStr[firstNonZeroIndex] === '0') {
        firstNonZeroIndex++;
    }

    // Calculate the position to round to
    const precision = firstNonZeroIndex - decimalIndex + 1;

    return parseFloat(value.toFixed(precision));
} else {
    return value; // If the value is 0 or negative, just return it
}
}