import React, { useEffect, useState } from 'react';
import './Selector.css'; // Import CSS file

export function Selector({
  placeholder,
  listItems,
  handleSelectionChange,
  selectedValue,
  isLoading,
  setSearch,
}: {
  placeholder: string;
  listItems: string[];
  selectedValue: string;
  handleSelectionChange: (update: any) => void;
  unitClassName?: string;
  isLoading?: boolean;
  setSearch?: (search: string) => void;
}) {
  const [inputValue, setInputValue] = useState('');
  const [isFocus, setIsFocus] = useState(false)
  const [isSearching, setIsSearching] = useState(false);
  const filteredItems = listItems.filter(item =>
    item.toLowerCase().slice(0, inputValue.length) === inputValue.toLowerCase() || inputValue.length === 0
  );
  const sortedItems = filteredItems.sort()

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch ? setSearch(e.target.value) : setInputValue(e.target.value)
    if (e.target.value.length !== 0) setIsSearching(true);
    if (e.target.value.length === 0) handleSelectionChange("")
  };

  const handleFocus = () => {
    setIsSearching(true)
    setIsFocus(true)
  }

  const handleBlur = () => {
    setTimeout(() => setIsSearching(false), 5)
    setIsFocus(false)
  }

  const handleClick = (item: string) => {
    handleSelectionChange(item);
    setInputValue(item);
    setIsSearching(false);
  }

  const handleClear = () => {
    setInputValue('')
    handleSelectionChange('')
  }

  useEffect(() => {
    setInputValue(selectedValue);
  }, [selectedValue])

  return (
    <div className='selector_container'>
      <span className="input_clear_span">
        <input
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="input" // Add className for styling
        />
        <div className={isFocus ? "input_clear_focus" : "input_clear"} onClick={handleClear}>{inputValue.length ? 'X' : ''}</div>
      </span>
      {isSearching && !isLoading && (filteredItems.length > 0 ? (
        <div
          className="menu_list" // Add className for styling
        >
          {sortedItems.map((item, i) => (
            <div
              key={item}
              onClick={() => handleClick(item)}
              className={item === selectedValue ? "menu_item_selected" : "menu_item"} // Add className for styling
            >
              {item}
            </div>
          ))}
        </div>
      ) : <div
        className="menu_list" // Add className for styling
      >
        <div
          key={"none"}
          className={"menu_item"} // Add className for styling
        >
          No result found
        </div>
      </div>)}
      {isLoading && <div
        className="menu_list" // Add className for styling
      >
        <div
          key={"none"}
          className={"menu_item"} // Add className for styling
        >
          Searching...
        </div>
      </div>}
    </div>
  );
}
