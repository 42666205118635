import axios from 'axios';
import createRefresh from 'react-auth-kit/createRefresh';
import createStore from 'react-auth-kit/createStore';

//Session refresh logic
const refresh = createRefresh({
  interval: 350,
  refreshApiCallback: async (param) => {
    try {
      const response = await axios.post(
        'http://192.168.50.157:4000/account/refresh',
        param,
        {
          headers: { Authorization: `Bearer ${param.authToken}` },
        }
      );
      return {
        isSuccess: true,
        newAuthToken: response.data.accessToken,
        newAuthTokenExpireIn: 350,
        newRefreshTokenExpiresIn: 7200,
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
        newAuthToken: '',
        newAuthTokenExpireIn: 350,
      };
    }
  },
});

//setup refresh logic
export const store = createStore({
  authName: '_auth',
  authType: 'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
  refresh,
});
