import { useCartContext } from '../hooks/useCartContext';
import './Basket.css';
import { BasketOverlay } from '../components/BasketOverlay';
import { storeListIcon } from '../components/resources';
import { Badge } from '@chakra-ui/react';
import { Icon } from '@blueprintjs/core';
import { useMemo } from 'react';
import { filterTitle } from '../components/utils';

export function Basket() {
  const { cartState } = useCartContext();
  const price = useMemo(() => {
    let price = 0
    for (const productId in cartState) {
      price += cartState[productId].price * cartState[productId].count
    }
    return price
  }
    , [cartState])

  const { savings, priceSelected, valueSelected } = useMemo(() => {
    let savings = 0
    let priceSelected = 0
    let valueSelected = 0
    for (const product of Object.values(cartState)) {
      savings += product.savingsProductPrice - product.price
      if (product.isPriceChoice) priceSelected++
      if (product.isValueChoice) valueSelected++
    }
    return { savings, priceSelected, valueSelected }
  }, [cartState])

  const isEmpty = Object.keys(cartState).length === 0;
  if (isEmpty)
    return (
      <div className="empty_container">
        <div className="empty_content">
          <Icon icon="shopping-cart" color="#AAAAAA" size={25} />
          <text>Your cart is empty</text>
        </div>
      </div>
    );

  return (
    <div className="page_container">
      <div className="cart_container">
        <div className="cart_header">
          <div className="cart_header_row">
            <span className="cart_header_span">
              <text className="cart_header_text">Items</text>
              <Badge fontSize={'16px'} borderRadius={'16px'}>{Object.keys(cartState).length}</Badge>
            </span>
            <span className="cart_header_span">
              <text className="cart_header_text">Total </text>
              <Badge fontSize={'16px'} borderRadius={'16px'}>R{price.toFixed(2)}</Badge>
            </span>
          </div>
        </div>
        <div className="cart_list">
          {Object.values(cartState).map((product) => (
            <div className="cart_row">
              <div className="cart_item">
                <img
                  src={storeListIcon[product.store]}
                  alt={product.title}
                  width={10}
                />
                <img src={product.image_url} alt={product.title} width={25} />
                <text className="cart_item_count">{product.count}</text>
                <text className="cart_item_text">
                  {filterTitle(product.title, product.volume, product.unit)}
                </text>
              </div>
              <div className="cart_edit">
                <text className="cart_item_price">
                  R{product.price * product.count}
                </text>
                {<BasketOverlay product={product} />}
              </div>
            </div>
          ))}
        </div>
        <div className="cart_savings">
          <span className="cart_savings_span">
            <text className="cart_savings_text">Savings</text>
            <Badge colorScheme="green" fontSize={'16px'} variant="subtle" borderRadius={'16px'}>
              R{savings.toFixed(2)}
            </Badge>
          </span>
          <span className="cart_savings_span">
            <text className="cart_savings_text">Cheapest items selected</text>
            <Badge colorScheme="green" fontSize={'16px'} variant="subtle" borderRadius={'16px'}>
              {priceSelected}
            </Badge>
            /
            <Badge colorScheme="green" fontSize={'16px'} variant="subtle" borderRadius={'16px'}>
              {Object.keys(cartState).length}
            </Badge>
          </span>
          <span className="cart_savings_span">
            <text className="cart_savings_text">Value items selected</text>
            <Badge colorScheme='blue' fontSize={'16px'} variant="subtle" borderRadius={'16px'}>
              {valueSelected}
            </Badge>
            /
            <Badge colorScheme='blue' fontSize={'16px'} variant="subtle" borderRadius={'16px'}>
              {Object.keys(cartState).length}
            </Badge>
          </span>
        </div>
      </div>
    </div>
  );
}
