/* eslint-disable */
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Login.css';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useState } from 'react';
import axios from 'axios';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CircularProgress,
  Spinner,
} from '@chakra-ui/react';
import { BACKEND_URL } from '../resources';

export function Login() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
    email: '',
  });
  const [error, setError] = useState('');
  const [isGuest, setIsGuest] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation();
  const currentPath = location.pathname;
  const isLogin = currentPath === '/login';
  const navigate = useNavigate();
  const signIn = useSignIn();
  const isMobile = window.innerWidth < 500;

  async function handleForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsLoading(true)
    let res;
    const { username, password, firstName, lastName, email } = formData;
    if (isLogin) {
      res = await axios.post(BACKEND_URL + '/account/login', {
        username: isGuest ? "TestUser" : username,
        password: isGuest ? "Pa$$word1!" : password,
      });
    } else {
      res = await axios.post(BACKEND_URL + '/account/signup', {
        firstName,
        lastName,
        username,
        password,
        email,
      });
    }
    if (res.status !== 200) return;
    if (res.data.error) {
      setError(res.data.error);
    }
    if (res.data.accessToken) {
      const { firstName, username, email, lastName } = res.data;
      const isSignedIn = signIn({
        auth: { token: res.data.accessToken, type: 'Bearer' },
        refresh: res.data.refreshToken,
        userState: { firstName, lastName, username, email },
      });
      if (isSignedIn) return navigate('/shop');
      return;
    }
    setIsLoading(false)
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  if (!isMobile) {
    return (<div className='mobile_message'>
      <h1 style={{fontWeight: "bold", fontSize: 36}}>Hi !</h1>
      <h1>This app was designed for mobile use</h1>
      <p>Open it on your mobile device to enjoy the experience.</p>
    </div>)
  }

  return (
    <div className="login_page_container">
      <form onSubmit={handleForm} className="form_container">
        <h1>PICK AND PRICE</h1>
        <h1 style={{fontSize: 16}}>v1</h1>
        {/* {isLogin ? (
          <>
            <input
              type="text"
              name="username"
              placeholder="Username"
              onChange={(e) => handleChange(e)}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={(e) => handleChange(e)}
            />
          </>
        ) : (
          <>
            <span>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                onChange={(e) => handleChange(e)}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                onChange={(e) => handleChange(e)}
              />
            </span>
            <input
              type="text"
              name="username"
              placeholder="Username"
              onChange={(e) => handleChange(e)}
            />
            <input
              type="text"
              name="email"
              placeholder="Email"
              onChange={(e) => handleChange(e)}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={(e) => handleChange(e)}
            />
          </>
        )} */}
        {/* {isLogin ? (
          <p>
            Don't have an account?{' '}
            <Link to="/signup" className="login_link">
              Sign up
            </Link>
          </p>
        ) : (
          <p>
            Already a user?{' '}
            <Link to="/login" className="login_link">
              Log in
            </Link>
          </p>
        )} */}
        {error && (
          <div className="login_alert">
            <Alert status="error" fontSize={14} borderRadius={'16px'}>
              <AlertIcon />
              <AlertTitle>Error:</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          </div>
        )}
        {isLogin && <button type="submit" className="form_button" onClick={() => setIsGuest(true)}>
          Enter as guest
        </button>}
        <Spinner color={isLoading ? "white": "transparent"}/>
        {/* <button type="submit" className="form_button">
          {isLogin ? 'Log in' : 'Sign up'}
        </button> */}
      </form>
    </div>
  );
}
