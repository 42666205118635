import { Outlet, Link, useNavigate } from 'react-router-dom';
import './Home.css';
import { Icon } from '@blueprintjs/core';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { Avatar } from '@chakra-ui/react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Badge } from "@chakra-ui/react";
import { useCartContext } from '../hooks';

const pathLookup: { [key: string]: string } = {
  '/shop': 'Shop',
  '/guide': 'Guide',
  '/profile': 'Profile',
  '/basket': 'Basket',
};

export function Home() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const signOut = useSignOut();
  const user = useAuthUser();
  const { username } = user ?? {} as any;
  const [showNav, setShowNav] = useState(false);
  const { cartState } = useCartContext()
  const itemsInCart = Object.keys(cartState).length

  function handleLogOut() {
    setShowNav(false);
    signOut();
  }

  return (
    <div className="app_container">
      <div className="top_nav_container">
        <Icon
          icon="menu"
          size={18}
          className="top_nav_container_menu"
          color="#DDDDDD"
          onClick={() => setShowNav(!showNav)}
        />
        {pathLookup[currentPath]}
        <span className='cart_span'>
          <Icon
            icon="shopping-cart"
            size={20}
            color="#DDDDDD"
            onClick={() => {
              return navigate('/basket');
            }}
            className="top_nav_container_cart"
          />
          {itemsInCart > 0 && <Badge
            colorScheme="green"
            borderRadius={'16px'}
            fontSize={8}
            height={3}

          >
            <text>{itemsInCart}</text>
          </Badge>}
        </span>
      </div>
      <AnimatePresence>
        {showNav && (
          <motion.div
            className="nav_container"
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            exit={{ x: -300 }}
            transition={{ type: 'spring', duration: 0.5 }}
          >
            <div className="nav_link nav_close">
              <Icon
                icon="minimize"
                size={16}
                color={'white'}
                onClick={() => setShowNav(false)}
              />
            </div>
            <div className='nav_user'>
              <Avatar bg="#777777" size={'xs'} />
              <text>{username}</text>
            </div>
            <Link
              to="shop"
              className={`nav_link ${currentPath === '/shop' ? 'selected' : ''
                }`}
              onClick={() => setShowNav(false)}
            >
              <Icon
                icon="shop"
                size={16}
                color={'white'}
                className="nav_link_icon"
              />
              <text>Shop</text>
            </Link>

            <Link
              to="guide"
              className={`nav_link ${currentPath === '/guide' ? 'selected' : ''
                }`}
              onClick={() => setShowNav(false)}
            >
              <Icon
                icon="manual"
                size={16}
                color={'white'}
                className="nav_link_icon"
              />
              <text>Guide</text>
            </Link>
            <Link
              to="profile"
              className={`nav_link ${currentPath === '/profile' ? 'selected' : ''
                }`}
              onClick={() => setShowNav(false)}
            >
              <Icon
                icon="user"
                size={16}
                color={'white'}
                className="nav_link_icon"
              />
              <text>Profile</text>
            </Link>
            <Link
              to="/login"
              className={'nav_link'}
              onClick={() => handleLogOut()}
            >
              <Icon
                icon="log-out"
                size={16}
                color={'white'}
                className="nav_link_icon"
              />
              <text>Logout</text>
            </Link>
          </motion.div>
        )}
      </AnimatePresence>
      {showNav && <div className="nav_backdrop"></div>}
      <Outlet />
    </div>
  );
}
