import ReactDOM from 'react-dom/client';
import App from './App';
import AuthProvider from 'react-auth-kit';
import { store } from './authconfig';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider store={store}>
    <App />
  </AuthProvider>
);
