const check = require('../assets/check.png');
const wool = require('../assets/wool.png');
const woolList = require('../assets/woolList.png');
const checkList = require('../assets/checkList.png');

export const storeIcon: {[key: string]: any} = {
  woolworths: wool,
  checkers: check,
  onlineShop: check,
};

export const storeListIcon : {[key: string]: any} = {
  woolworths: woolList,
  checkers: checkList,
  onlineShop: checkList,
};

export const imageWidths: { [key: string]: number } = {
  woolworths: 150,
  checkers: 100,
};