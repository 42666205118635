import { useState } from "react";
import { JobType, ProductInfo, RankingDetails, SearchState, SelectionState } from "../types";
import "./ProductCard.css";
import { Icon } from "@blueprintjs/core";
import { Badge, IconButton } from "@chakra-ui/react";
import { useCartContext, useSelectionContext } from "../hooks";
import { imageWidths, storeIcon } from "./resources";
import { filterTitle, roundNonZero } from "./utils";
import { BellIcon } from "@chakra-ui/icons";
import axios from "axios";
import { BACKEND_URL } from "../resources";

export function ProductCard({
  product,
  searchState,
  rankingDetails,
  savingsProduct
}: {
  product: ProductInfo;
  searchState: SearchState;
  rankingDetails: RankingDetails;
  savingsProduct: ProductInfo
}) {
  const [count, setCount] = useState(1);
  const { cartState, setCartState } = useCartContext();
  const { selectionState, setSelectionState } = useSelectionContext();
  const [isProductFlagged, setIsProductFlagged] = useState(product.is_flagged)

  function handleAddProduct() {
    setCartState((prev) => ({
      ...prev,
      [product.id]: {
        ...product,
        count,
        savingsProductId: savingsProduct.id,
        savingsProductPrice: savingsProduct.price,
        isPriceChoice: isPriceProduct,
        isValueChoice: isValueProduct
      },
    }));
    setSelectionState((prev: SelectionState) => ({
      ...prev,
      [product.id]: searchState,
    }));
  }

  function handleRemoveProduct() {
    const newCartState = { ...cartState };
    const newSelectionState = { ...selectionState };
    delete selectionState[product.id];
    delete newCartState[product.id];
    setCartState(newCartState);
    setSelectionState(newSelectionState);
  }

  async function handleFlagProduct () {
    setIsProductFlagged(true)
    const payload = {
      jobType: JobType.FLAG_PRODUCT,
      params: {
        id: product.id
      },
    };
    const res = await axios.post(BACKEND_URL + '/job/initiate', payload);
    return
  }

  const productValue = roundNonZero(product.price / product.category_volume)
  const isPriceProduct = rankingDetails.bestPriceProductId === product.id
  const isValueProduct = rankingDetails.bestValueProductId === product.id

  return (
    <div className="card_container">
      {cartState[product.id] && <div className="card_overlay"></div>}
      <div className="card_container_top">
        <span className="card_container_store_chips">
          <img src={storeIcon[product.store]} width={50} alt={"check"} />
          <span className="card_chips">
            {isPriceProduct &&
              <Badge
                colorScheme="green"
                paddingLeft={"8px"}
                paddingRight={"8px"}
                borderRadius={"8px"}
                alignContent={'center'}
                paddingBottom={"2px"}
              >
                <text className="card_price_chip">Price</text>
              </Badge>}
            {isValueProduct &&
              <Badge
                colorScheme='blue'
                paddingLeft={"8px"}
                paddingRight={"8px"}
                borderRadius={"8px"}
                alignContent={'center'}
                paddingBottom={"2px"}
              >
                <text className="card_price_chip">Value</text>
              </Badge>
            }
            <IconButton onClick={handleFlagProduct} variant='outline' aria-label="flag" size='xs' icon={<BellIcon color={'inherit'}/>} colorScheme={isProductFlagged ? 'red' : 'gray'} color={isProductFlagged ? '#b53b3b' : '#b8b7b7'}/>
          </span>
        </span>
        <text className="card_title">{filterTitle(product.title, product.volume, product.unit)}</text>
        <div className="card_container_middle">
          <div className="card_container_left">
            <text className="card_brand">{product.brand}</text>
            <text className="card_volume">
              {product.volume} {product.unit}
            </text>
            <text className="card_volume">{`(R${productValue}/${product.category_unit})`}</text>
            <text className="card_price">
              <Icon icon="tag" color="#666666;" size={10} />R
              {Number(product.price) * count}
            </text>
          </div>
          <div className="card_container_right">
            <img
              src={product.image_url}
              alt={product.title}
              width={imageWidths[product.store]}
            />
          </div>
        </div>
      </div>
      <div className="card_container_bottom">
        <span className="card_unit_count_container">
          <span className="unit_icon">
            {" "}
            <Icon
              icon="minus"
              color="white"
              size={14}
              onClick={() => setCount(count - 1 <= 1 ? 1 : count - 1)}
            />
          </span>
          <div className="card_unit_count">{count}</div>
          <span className="unit_icon">
            <Icon
              icon="plus"
              color="white"
              size={14}
              onClick={() => setCount(count + 1)}
            />
          </span>
        </span>
        {cartState[product.id] ? (
          <button className="card_remove_button" onClick={handleRemoveProduct}>
            Remove
          </button>
        ) : (
          <button className="card_add_button" onClick={handleAddProduct}>
            Add
          </button>
        )}
      </div>
    </div>
  );
}
